import { Link } from "react-router-dom";
import { Typography } from "@pongo";

interface BreadcrumbsProps {
  root: {
    name: string | undefined;
    url: string;
  };
  stem?: string;
}

export const Breadcrumbs = ({ root, stem }: BreadcrumbsProps) => (
  <div className="w-full flex justify-start items-center pt-[24px]">
    <Link to={root.url}>
      <Typography
        weight="semi-bold"
        className="text-brand-heavy-teal-100/50 hover:text-brand-heavy-teal-75 cursor-pointer"
        variant="sm"
      >
        {root.name || "Unknown"}
      </Typography>
    </Link>

    <BreadcrumbSeparator />

    <Typography
      weight="semi-bold"
      className="text-brand-heavy-teal-100/80"
      variant="sm"
    >
      {stem || "Unknown"}
    </Typography>
  </div>
);

const BreadcrumbSeparator = () => (
  <Typography
    weight="semi-bold"
    className="text-brand-heavy-teal-100/50 cursor-pointer relative top-[0px]"
  >
    <svg
      width="22"
      height="22"
      viewBox="0 0 25 25"
      fill="none"
      stroke="currentColor"
    >
      <path d="M10.5 8L15 12.5L10.5 17" strokeWidth="1.2" />
    </svg>
  </Typography>
);
