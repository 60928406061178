import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ApiController } from "../../../service/ApiController";
import { Table } from "@/components/Table/Table";
import { FullPageLoader } from "../../../pages/FullPageLoader/FullPageLoader";
import { formatCurrency, getAddressAsString } from "@shared/utils";
import { useTransactionsGroupContext } from "../../context/TransactionsGroupProvider";
import { TransactionsGroupState } from "@schema";
import { ErrorText } from "@pongo";

interface TransactionsRow {
  _id: string;
  propertyAddress: string;
  askingPrice: string;
  estateAgent?: string;
  sellerSolicitor?: string;
  buyerSolicitor?: string;
}

interface TransactionsTable {
  transactionsGroupId?: string;
}

export const TransactionsTable = ({
  transactionsGroupId,
}: TransactionsTable) => {
  const navigate = useNavigate();
  const { setTransactionsGroup } = useTransactionsGroupContext();

  const [transactionInfo, setTransactionInfo] = useState<TransactionsRow[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const { id: routeId } = useParams<{ id: string }>();
  const groupId = transactionsGroupId || routeId;

  const columns = useMemo(
    () => [
      { key: "propertyAddress", title: "Property Address" },
      { key: "askingPrice", title: "Asking Price" },
      { key: "sellerSolicitor", title: "Seller Solicitor" },
      { key: "buyerSolicitor", title: "Buyer Solicitor" },
      { key: "estateAgent", title: "Estate Agent" },
    ],
    [],
  );

  const displayedTransactions = useMemo(() => {
    return transactionInfo.map(
      ({
        _id,
        propertyAddress,
        askingPrice,
        estateAgent,
        sellerSolicitor,
        buyerSolicitor,
      }) => ({
        data: {
          _id,
          propertyAddress,
          askingPrice,
          sellerSolicitor,
          buyerSolicitor,
          estateAgent,
        },
        onClick: () => navigate(`/admin/transactions/${_id}`),
      }),
    );
  }, [transactionInfo, navigate]);

  useEffect(() => {
    const errorMsg = "There was an error while fetching transactions groups";

    const getTransactionsFromGroup = async () => {
      if (!groupId) {
        setError(`${errorMsg}. No valid group ID provided.`);
        setLoading(false);
        return;
      }

      setLoading(true);
      setError("");

      try {
        const transactions = await ApiController.findAllTransactions({
          groupId,
        });

        if (transactions) {
          const transactionInfo = transactions.map((transaction) => {
            const {
              _id,
              property_id,
              asking_price,
              estate_agent,
              lawyer_group,
            } = transaction;

            return {
              _id,
              propertyAddress: getAddressAsString(property_id.address, true),
              askingPrice: formatCurrency(asking_price, true, true),
              estateAgent:
                estate_agent &&
                `${estate_agent?.brand}-${estate_agent?.office.name}`,
              sellerSolicitor: lawyer_group?.seller
                ? `${lawyer_group?.seller.solicitor?.first_name} ${lawyer_group?.seller.solicitor?.last_name}`
                : "",
              buyerSolicitor: lawyer_group?.buyer
                ? `${lawyer_group?.buyer.solicitor?.first_name} ${lawyer_group?.buyer.solicitor?.last_name}`
                : "",
            };
          });

          setTransactionInfo(transactionInfo);

          const hasEstateAgent = transactionInfo.some((transaction) =>
            Boolean(transaction.estateAgent),
          );

          const hasLawyerGroups = transactionInfo.some((transaction) =>
            Boolean(transaction.sellerSolicitor && transaction.buyerSolicitor),
          );

          if (hasEstateAgent) {
            setTransactionsGroup((prev: TransactionsGroupState) => ({
              ...prev,
              estateAgent: transactionInfo[0].estateAgent,
            }));
          } else {
            setTransactionsGroup((prev: TransactionsGroupState) => ({
              ...prev,
              estateAgent: undefined,
            }));
          }

          if (hasLawyerGroups) {
            setTransactionsGroup((prev: TransactionsGroupState) => ({
              ...prev,
              lawyerGroups:
                transactionInfo[0].sellerSolicitor &&
                transactionInfo[0].buyerSolicitor,
            }));
          } else {
            setTransactionsGroup((prev: TransactionsGroupState) => ({
              ...prev,
              lawyerGroups: undefined,
            }));
          }
        }
      } catch (error) {
        if (error instanceof Error) {
          setError(errorMsg);
        }
      } finally {
        setLoading(false);
      }
    };

    getTransactionsFromGroup();
  }, [groupId, setTransactionsGroup]);

  return (
    <>
      {loading && <FullPageLoader />}
      {error ? (
        <ErrorText>{error}</ErrorText>
      ) : (
        <Table columns={columns} rows={displayedTransactions} />
      )}
    </>
  );
};
